type GlobalRoutingT =
  | "facilities"
  | "members"
  | "promotions"
  | "booking"
  | "sites"
  | "notifications"
  | "help"
  | "tools"
  | "advertising"
  | "invoices"
  | "feedbacks";

type tabComponentsT = {
  general: string;
  rental: string;
  paymentHistory: string;
  creditMemo: string;
  points: string;
  add: string;
};

type RoutingVariants = {
  list: string;
  add?: string;
  get?: string;
  book?: string;
  tabComponents?: tabComponentsT;
  door?: string;
  [key: string]: RoutingVariants | string | any;
};

type RouterT = Record<GlobalRoutingT, RoutingVariants>;

export const PrivateRoutes: RouterT = {
  promotions: {
    list: "/promotions",
    add: "/promotions/add",
    get: "/promotions/:id",
  },
  facilities: {
    list: "/facilities",
    add: "/facilities/add",
    get: "/facilities/:id",
    book: "/facilities/:id/book",
  },
  members: {
    list: "/members",
    add: "/members/add",
    rental: "/members/add/rental",
    get: "/members/:id",
    tabComponents: {
      general: "/members/:id/general",
      rental: "/members/:id/rental",
      paymentHistory: "/members/:id/paymentHistory",
      creditMemo: "/members/:id/creditMemo",
      points: "/members/:id/points",
      add: "/members/:id/rental/add",
    },
  },
  invoices: {
    list: "/invoices",
  },
  booking: {
    list: "/booking",
  },
  sites: {
    list: "/sites",
    add: "/sites/add",
    get: "/sites/:id",
  },
  notifications: {
    list: "/notifications",
    add: "/notifications/add",
    get: "/notifications/:id",
  },
  help: {
    list: "/help",
    add: "/help/add",
    get: "/help/:id",
  },
  advertising: {
    list: "/advertising",
    add: "/advertising/add",
    get: "/advertising/:id",
  },
  tools: {
    list: "/tools",
    add: "/tools/invoices",
    door: "/tools/doorPin",
  },
  feedbacks: {
    list: "/feedbacks",
    get: "/feedbacks/:id",
  },
};
