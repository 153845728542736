import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { BACK_END_API_ENDPOINT } from "./constants";

export const resetMemberPassword = async (
  { ...values }: any,
  uid: string,
  token: string,
  history: History,
  setPassIsReset: any
) => {
  const newPassword = values.password;
  const notyf = new Notyf({ duration: 10000, dismissible: true });
  let p1 = axios
    .post(`${BACK_END_API_ENDPOINT} /reset_password_confirm/`, {
      uid: uid,
      token: token,
      new_password: newPassword,
    })
    .then((response) => {
      notyf.success("Password was updated.");
      console.log("true");
      setPassIsReset(true);
      
      return true;
    })
    .catch((error) => error)
    .then((errorData) => {
      type errorMapT = {
        [key: string]: Array<string>;
      };
      const errorsMap: errorMapT = errorData.response.data;

      console.log(errorData);
      Object.entries(errorsMap).forEach((entry) => {
        const [field, errors] = entry;
        errors.forEach((errorText: string) => {
          notyf.error(`Error with ${field} field, message: ${errorText}`);
        });
      });
      return false;
    });

  return p1;
};
