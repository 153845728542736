import { PrivateRoutes } from "../constants/routes";
import { HeaderPagesDataT } from "./headerTypes";

export const headerData: HeaderPagesDataT[] = [
  {
    pathName: PrivateRoutes.promotions.list,
    select: true,
    search: "Search by Promo ID",
    button: "+ Add New Promo",
  },
  {
    pathName: PrivateRoutes.promotions.add,
    backBtn: {
      pathName: PrivateRoutes.promotions.list,
      textContent: "Back to Promotions list",
    },
  },
  {
    pathName: PrivateRoutes.promotions.get,
    backBtn: {
      pathName: PrivateRoutes.promotions.list,
      textContent: "Back to Promotions list",
    },
  },
  {
    pathName: PrivateRoutes.facilities.list,
    select: true,
    search: "Search by Facility Type",
    button: "+ Add New Facility Page",
  },
  {
    pathName: PrivateRoutes.facilities.add,
    backBtn: {
      pathName: PrivateRoutes.facilities.list,
      textContent: "Back to Facilities list",
    },
  },
  {
    pathName: PrivateRoutes.facilities.get,
    backBtn: {
      pathName: PrivateRoutes.facilities.list,
      textContent: "Back to Facilities list",
    },
  },
  {
    pathName: PrivateRoutes.facilities.book,
    backBtn: {
      pathName: PrivateRoutes.facilities.list,
      textContent: "Back to Facilities list",
    },
  },
  {
    pathName: PrivateRoutes.booking.list,
    select: true,
    search: "Search by Booking Date or User email",
  },
  {
    pathName: PrivateRoutes.members.list,
    select: true,
    search: "Search by Username or Email or Unit ID",
    button: "+ Add New User",
  },
  {
    pathName: PrivateRoutes.members.add,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.rental,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.get,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.general,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.rental,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
    button: "Add New Rental details",
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.add,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.paymentHistory,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.creditMemo,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.members.tabComponents?.points,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to Users list",
    },
  },
  {
    pathName: PrivateRoutes.sites.list,
    search: "Search by Address",
    button: "+ Add New Site",
  },
  {
    pathName: PrivateRoutes.sites.add,
    backBtn: {
      pathName: PrivateRoutes.sites.list,
      textContent: "Back to Sites list",
    },
  },
  {
    pathName: PrivateRoutes.sites.get,
    backBtn: {
      pathName: PrivateRoutes.sites.list,
      textContent: "Back to Sites list",
    },
  },
  {
    pathName: PrivateRoutes.notifications.list,
    search: "Search by keywords or User Emails",
    button: "+ Send New Notification",
  },
  {
    pathName: PrivateRoutes.notifications.add,
    backBtn: {
      pathName: PrivateRoutes.notifications.list,
      textContent: "Back to Notifications list",
    },
  },
  {
    pathName: PrivateRoutes.help.list,
    search: "Search by keywords",
    button: "+ Add New FAQ",
  },
  {
    pathName: PrivateRoutes.help.add,
    backBtn: {
      pathName: PrivateRoutes.help.list,
      textContent: "Back to FAQ’s list",
    },
  },
  {
    pathName: PrivateRoutes.tools.add,
    backBtn: {
      pathName: PrivateRoutes.members.list,
      textContent: "Back to members list",
    },
  },
  {
    pathName: PrivateRoutes.tools.door,
    text: "Door Passcode",
  },
  {
    pathName: PrivateRoutes.advertising.list,
    button: "+ Add New Advertising",
    search: "Search by Advertising ID",
  },
  // {
  //   pathName: PrivateRoutes.feedbacks.list,
  //   search: "Search by Feedbacks",
  // },
  // {
  //   pathName: PrivateRoutes.feedbacks.get,
  //   backBtn: {
  //     pathName: PrivateRoutes.feedbacks.list,
  //     textContent: "Back to feedbacks list",
  //   },
  // },
  {
    pathName: PrivateRoutes.advertising.add,
    backBtn: {
      pathName: PrivateRoutes.advertising.list,
      textContent: "Back to Sites Advertising list",
    },
  },
  {
    pathName: PrivateRoutes.advertising.get,
    backBtn: {
      pathName: PrivateRoutes.advertising.list,
      textContent: "Back to Sites Advertising list",
    },
  },
  {
    pathName: PrivateRoutes.invoices.list,
    select: true,
    search: "Search by Invoice Number",
  },
];
