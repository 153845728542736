import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  GetAdvertising,
  ListAdvertising,
  NewAdvertising,
} from "../../scenes/Advertising";
import { ListBooking } from "../../scenes/Booking";
import {
  BookFacilities,
  GetFacilities,
  ListFacilities,
  NewFacilities,
} from "../../scenes/Facilities";
import {
  FacilitiesPromotion,
  GetPromotion,
  NewPromotion,
} from "../../scenes/FacilitiesPromotion";
import { AddHelp, HelpList } from "../../scenes/Help";
import { AddMember, GetMember, ListMembers } from "../../scenes/Members";
import { AddRental } from "../../scenes/Members/AddRental/AddRental";
import { AddNotification, ListNotifications } from "../../scenes/Notifications";
import { GetSite, ListSites } from "../../scenes/Sites";
import { AddSite } from "../../scenes/Sites/AddSite/AddSite";
import { Invoices } from "../../scenes/Tools";
import { DoorPin } from "../../scenes/Tools/DoorPin/DoorPin";
import { PrivateRoutes } from "../../utils/constants/routes";
import { ManagerForms } from "../Modals/ManagerForms/ManagerForms";
import s from "./PageContent.module.scss";
import { ListInvoices } from "../../scenes/Invoices";
import { ListFeedbacks } from "../../scenes/Feedbacks/ListFeedback/ListFeedback";
import { GetFeedback } from "../../scenes/Feedbacks/GetFeedback/GetFeedback";

export const PageContent = () => (
  <div className={s.pageContent}>
    <Switch>
      <Route path={PrivateRoutes.members.rental} exact>
        <AddRental />
      </Route>
      <Route path={PrivateRoutes.members.list} exact>
        <ListMembers />
      </Route>
      <Route path={PrivateRoutes.members.add}>
        <AddMember />
      </Route>
      <Route path={PrivateRoutes.members.get}>
        <GetMember />
      </Route>

      {/* <Route path={PrivateRoutes.feedbacks.list} exact>
         <ListFeedbacks/>
      </Route> */}
      {/* <Route path={PrivateRoutes.feedbacks.get} exact>
         <GetFeedback/>
      </Route> */}

      <Route path={PrivateRoutes.invoices.list} exact>
        <ListInvoices />
      </Route>

      <Route path={PrivateRoutes.promotions.list} exact>
        <FacilitiesPromotion />
      </Route>
      <Route path={PrivateRoutes.promotions.add}>
        <NewPromotion />
      </Route>
      <Route path={PrivateRoutes.promotions.get}>
        <GetPromotion />
      </Route>
      <Route path={PrivateRoutes.sites.list} exact>
        <ListSites />
      </Route>
      <Route path={PrivateRoutes.sites.add}>
        <AddSite />
      </Route>
      <Route path={PrivateRoutes.sites.get}>
        <GetSite />
      </Route>
      <Route path={PrivateRoutes.notifications.list} exact>
        <ListNotifications />
      </Route>
      <Route path={PrivateRoutes.notifications.add}>
        <AddNotification />
      </Route>
      <Route path={PrivateRoutes.help.list} exact>
        <HelpList />
      </Route>
      <Route path={PrivateRoutes.help.add}>
        <AddHelp />
      </Route>

      <Route path={PrivateRoutes.facilities.list} exact>
        <ListFacilities />
      </Route>
      <Route path={PrivateRoutes.facilities.add} exact>
        <NewFacilities />
      </Route>
      <Route path={PrivateRoutes.facilities.get} exact>
        <GetFacilities />
      </Route>
      <Route path={PrivateRoutes.facilities.book} exact>
        <BookFacilities />
      </Route>

      <Route path={PrivateRoutes.booking.list} exact>
        <ListBooking />
      </Route>

      <Route path={PrivateRoutes.advertising.list} exact>
        <ListAdvertising />
      </Route>

      <Route path={PrivateRoutes.advertising.add} exact>
        <NewAdvertising />
      </Route>
      <Route path={PrivateRoutes.advertising.get} exact>
        <GetAdvertising />
      </Route>

      <Route path={PrivateRoutes.tools.add} exact>
        <Invoices />
      </Route>
      <Route path={PrivateRoutes.tools.door} exact>
        <DoorPin />
      </Route>

      <Redirect to={PrivateRoutes.members.list} />
    </Switch>
    <ManagerForms />
  </div>
);

export default PageContent;
